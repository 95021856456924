<template lang="pug">
TableV2(
  type="positionStatement"
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData").px-4
    Add(slot="AddBlock")
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SailorPositionStatement',
  components: {
    Add: () => import('./Add.vue')
  },
  data () {
    return {
      actions: [
        {
          id: 1,
          to: (item) => ({ name: 'position-statements-info', params: { id: this.$route.params.id, documentID: item.id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ],
      headers: [
        { value: 'created_at', text: this.$t('createDate') },
        { value: 'full_number', text: this.$t('number') },
        { value: 'branch_office', text: this.$t('affiliate') },
        { value: 'rank', text: this.$t('rank') },
        { value: 'list_positions', text: this.$t('position') },
        { value: 'include_sailor_passport', text: this.$t('model-SailorPassport') },
        { value: 'price', text: this.$t('price') },
        { value: 'type_exam', text: this.$t('typeExam') },
        { value: 'isPayedPosition', text: this.$t('payment') },
        { value: 'event', text: this.$t('actions'), class: 'mw-0 position-relative' }
      ],
      newDoc: false,
      tableLoader: false
    }
  },
  computed: {
    ...mapState({
      items: state => state.sailor.paketStatements,
      isLoading: state => state.sailor.isLoading
    })
  },
  methods: {
    ...mapActions(['getPacketStatement']),
    async getData (params) {
      await this.getPacketStatement({ ...this.$route.params, params })
    }
  }
}

</script>
